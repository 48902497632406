.card {
    transition: transform 0.6s ease, box-shadow 0.6s ease;
}

.card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}


.btn {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
    transform: translateY(-2px);
    background-color: #0056b3; /* Adjust this for a vibrant hover color */
}

.edit-btn {
    position: absolute;
    top: 3px;
    left: 3px;
    color: white;
    background-color: #111;
    text-decoration: none;
    font-size:1rem;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 5px;;
}