.floating-alert {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);
    padding: 20px 25px;
    border-radius: 8px;
    font-weight: bold;
    min-width: 300px;
    text-align: center;
    z-index: 1050;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    max-width: 700px;

}

/* Show alert */
.floating-alert.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
}

.floating-alert .close-btn {
    position: absolute;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
    color: inherit;
    top: -7px;
    right: 0;
}