/* ✅ Ensures the footer sticks to the bottom */
html, body, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-container {
  flex-grow: 1;
  padding-bottom: 50px; /* Ensures footer does not overlap content */
}


/* ✅ Sticky footer */
footer {
  width: 100%;
  background-color: #f8f9fa; /* Example styling */
  padding: 10px 0;
  text-align: center;
  position: relative;
  bottom: 0;
}


@media (min-width: 768px) {
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 0rem!important;
}
}