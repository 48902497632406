/* Custom Navbar */
.custom-navbar {
    position: sticky;
    top: 0;
    width: 100%;
    background: rgba(52, 58, 64, 0.9);
    backdrop-filter: blur(8px);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    pointer-events: none; /* ✅ Allow scrolling through */
}

.custom-navbar * {
    pointer-events: auto; /* ✅ Allows clicking on nav links and buttons */
}

.custom-navbar {
    overflow: visible;
}

.custom-navbar .container {
    display: flex;
    align-items: center;
    width: 100%;
}

.custom-navbar .logo {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.custom-navbar .logo img {
    height: 40px;
    margin-right: 10px;
    display: none;
}

.custom-navbar .logo img[src] {
    display: block;
}

.custom-navbar .badge {
    font-size: 0.8rem;
    color: yellow;
    margin-left: 5px;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: all 0.3s ease;
    padding: 10px 15px;
}

.nav-links a:hover {
    color: #f8f9fa;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.user-menu {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
}

.user-menu .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    flex-direction: column;
    width: 10em;
}

.user-menu:hover .dropdown {
    display: flex;
}

.user-menu .dropdown a, .user-menu .dropdown button {
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    width: 100%;
    font-size: 1rem;
    color: black;
    cursor: pointer;
}

.user-menu .dropdown a:hover, .user-menu .dropdown button:hover {
    background: #f8f9fa;
}

@media (max-width: 768px) {
    .nav-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        background: rgba(52, 58, 64, 0.95);
        width: 200px;
        padding: 10px;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        pointer-events: auto; /* Prevent clicks when closed */
    }

    /* ✅ Show Mobile Navigation when active */
    .nav-links.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        pointer-events: auto; /* Enable clicks when menu is open */
    }

    /* ✅ Ensure menu toggle is visible on mobile */
    .menu-toggle {
        display: block;
        position: absolute;
        right: 1em;
    }

    .nav-links a {
        width: 100%;
        text-align: center;
    }
}

/* Top Bar */
.top-bar {
    background-color: #111;
    color: #f5f5f5;
    padding: 4px 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.top-bar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto; /* Center align */
    padding: 0 20px; /* Match navbar padding */
}

/* Top Logo */
.top-logo {
    text-decoration: none;
    color: #f5f5f5;
    font-weight: bold;
    font-size: 16px;
}

/* Login / Logout Button */
.top-login .login-btn, .top-login .logout-btn {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 4px;
    transition: 0.3s;
}

.top-login .login-btn:hover, .top-login .logout-btn:hover {
    text-decoration: underline;
    color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .top-bar .container {
        flex-direction: column;
        text-align: center;
    }

    .top-login {
        margin-top: 5px;
    }
}

.custom-navbar .container, .top-bar .container {
    padding: 0px;
}