html{
  background-color: #f5f5f5;

}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 30px;
  background-color: #f5f5f5;
}


html, body {
  overflow: hidden;
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer {
  background-color: #343a40; /* Dark background color for footer */
  color: white; /* White text color for footer */
  padding: 1rem 0; /* Padding for footer */
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Instrument Sans", -apple-system, BlinkMacSystemFont, 'Arial', sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  margin-bottom: 20px;
}

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.selected-images img {
  margin: 5px;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.smallSup {
  font-size:10px;
  opacity: 0.5;
}

.card-body .btn {
  margin-right: .2rem;
  margin-bottom: .2rem;
}

.card-body .btn:last-child {
  margin-right: 0 !important;
}

.buildYearSubtitle {
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 14px;
  opacity: .85;
  padding: 0.2rem 0.5rem;
  position: absolute;
  right: .4em;
  top: .3em;
}

.card-title {
  width: 90%;
}

.card.mb-4 {
  box-shadow: 1px 1px 2px rgb(0 0 0 / 5%);
}


.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-4 {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.loginBtn {
  font-size: small;
}

.btn-outline-light.active {
  background-color: white;
}

.contentBodyLightContainer {
  background-color: white;
  padding: 20px 30px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 1em;

}

.contentBodyContainer {
  margin-top: 1em;
}

@media (min-width: 768px) {
  .text-md-left {
      text-align: left !important;
  }
  .text-md-right {
      text-align: right !important;
  }
}

.container.flex-grow-1 {
  margin-top: 100px;
}